@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.formborder {
  background-color: rgb(250, 250, 250);
  box-shadow: 0 1px 2px 0 rgba(117, 117, 117, 0.25);
}

.icon {
  color: #fea119;
  font-size: 27px;
}

.alerticon {
  color: red;
  font-size: 27px;
}

.icondisabled {
  color: #cfcccc;
  font-size: 27px;
}

.linklabel {
  color: blue;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.btn,
.btn-primary {
  background-color: #fea119 !important;
  border-color: #fea119 !important;
}

.clickablelink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 200 !important;
}

.propertytitle {
  color: #529f20;
  font-size: 25px;
  margin-bottom: 10px;
}

.propertyname {
  color: black;
  font-weight: bolder;
  font-size: 15px;
}
.propertyvalue {
  color: #726e6e;
  font-size: 15px;
}

.templatecontent {
  background-color: #dfd9d9;
  color: #726e6e;
}
.seen {
  color: #726e6e;
}
.notseen {
  color: #201dcf;
  font-weight: 400;
}

/* Navbar */

.nav-menu-items {
  width: 100%;
}

.nav-menu {
  background-color: #15455a;
  width: 180px;
  height: 91vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-menu-mobile {
  background-color: #15455a;
  width: 180px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 10;
}

.nav-menu-mobile.active {
  left: 0;
  transition: 350ms;
  z-index: 10;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 8px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #fea119;
  color: white;
}

/* Spinner */

.spinner {
  position: fixed;
  z-index: 99; /* make higher than whatever is on the page */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  border: 5px dotted #dadada;
  border-top: 5px solid #4caf50;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 720px) {
  .icon {
    color: #009688;
    font-size: 35px;
  }

  .alerticon {
    color: red;
    font-size: 35px;
  }

  .icondisabled {
    color: gray;
    font-size: 35px;
  }
}
