@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.formborder {
  background-color: rgb(250, 250, 250);
  box-shadow: 0 1px 2px 0 rgba(117, 117, 117, 0.25);
}

.icon {
  color: #fea119;
  font-size: 27px;
}

.alerticon {
  color: red;
  font-size: 27px;
}

.icondisabled {
  color: #cfcccc;
  font-size: 27px;
}

.linklabel {
  color: blue;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.btn,
.btn-primary {
  background-color: #fea119 !important;
  border-color: #fea119 !important;
}

.clickablelink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 200 !important;
}

.propertytitle {
  color: #529f20;
  font-size: 25px;
  margin-bottom: 10px;
}

.propertyname {
  color: black;
  font-weight: bolder;
  font-size: 15px;
}
.propertyvalue {
  color: #726e6e;
  font-size: 15px;
}

.templatecontent {
  background-color: #dfd9d9;
  color: #726e6e;
}
.seen {
  color: #726e6e;
}
.notseen {
  color: #201dcf;
  font-weight: 400;
}

/* Navbar */

.nav-menu-items {
  width: 100%;
}

.nav-menu {
  background-color: #15455a;
  width: 180px;
  height: 91vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-menu-mobile {
  background-color: #15455a;
  width: 180px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 10;
}

.nav-menu-mobile.active {
  left: 0;
  transition: 350ms;
  z-index: 10;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 8px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #fea119;
  color: white;
}

/* Spinner */

.spinner {
  position: fixed;
  z-index: 99; /* make higher than whatever is on the page */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  border: 5px dotted #dadada;
  border-top: 5px solid #4caf50;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 720px) {
  .icon {
    color: #009688;
    font-size: 35px;
  }

  .alerticon {
    color: red;
    font-size: 35px;
  }

  .icondisabled {
    color: gray;
    font-size: 35px;
  }
}

.hc {
  justify-content: center;
}

.hl {
  justify-content: left;
}

.hr {
  justify-content: right;
}

.vc {
  align-items: center;
}

.vr {
  align-items: right;
}

.vl {
  align-items: left;
}

.pt0 {
  padding-top: 0px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pt3 {
  padding-top: 3px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt7 {
  padding-top: 7px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}
.pb2 {
  padding-bottom: 2px !important;
}
.pb3 {
  padding-bottom: 3px !important;
}
.pb5 {
  padding-bottom: 4px !important;
}
.pb7 {
  padding-bottom: 7px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}

.pl0 {
  padding-left: 0px !important;
}
.pl2 {
  padding-left: 2px !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl7 {
  padding-left: 7px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl20 {
  padding-left: 20px !important;
}

.pr0 {
  padding-right: 0px !important;
}
.pr2 {
  padding-right: 2px !important;
}
.pr3 {
  padding-right: 3px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr7 {
  padding-right: 7px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr20 {
  padding-right: 20px !important;
}

.p0 {
  padding: 0px 0px 0px 0px !important;
}

.p5 {
  padding: 5px 5px 5px 5px !important;
}
.p10 {
  padding: 10px 10px 10px 10px !important;
}
.p15 {
  padding: 15px 15px 15px 15px !important;
}

.p20 {
  padding: 20px 20px 20px 20px !important;
}

.container-p {
  padding: 25px 10px 10px 40px !important;
}

.m0 {
  margin: 0px 0px 0px 0px !important;
}
.m5 {
  margin: 5px 5px 5px 5px !important;
}

.m10 {
  margin: 10px 10px 10px 10px !important;
}
.m15 {
  margin: 15px 15px 15px 15px !important;
}

.m20 {
  margin: 20px 20px 20px 20px !important;
}

.mt0 {
  margin-top: 0px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt7{
  margin-top: 7px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt36 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0px !important;
}
.ml2 {
  margin-left: 2px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml35 {
  margin-left: 35px !important;
}
.ml50 {
  margin-left: 50px !important;
}

.ml-15 {
  margin-left: -15px !important;
}

.mr0 {
  margin-right: 0px !important;
}
.mr2 {
  margin-right: 2px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr35 {
  margin-right: 35px !important;
}

.mr-15 {
  margin-right: -15px !important;
}

.fs5 {
  font-size: 5px;
}
.fs10 {
  font-size: 10px;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}

.fs17 {
  font-size: 17px;
}
.fs20 {
  font-size: 20px;
}
.fs23 {
  font-size: 23px;
}

.fs25 {
  font-size: 25px;
}

.fs27 {
  font-size: 27px;
}
.fs30 {
  font-size: 30px;
}
.fs35 {
  font-size: 35px;
}

.fb {
  font-weight: bold;
}

.mw300 {
  max-width: 300px !important;
}
.mw350 {
  max-width: 350px !important;
}
.mw500 {
  max-width: 500px !important;
}
.mw600 {
  max-width: 600px !important;
}
.mw700 {
  max-width: 700px !important;
}
.mw750 {
  max-width: 750px !important;
}

.mw99p {
  max-width: 99% !important;
}

.mh500{
  max-height: 500px !important;
}

.mh300{
  max-height: 300px !important;
}

.fcwhite {
  color: white;
}
.fcmaroon {
  color: #f44336;
}

.fcgreen {
  color: #4caf50;
}

.fcblue {
  color: blue;
}

.bggreen {
  background-color: #4caf50;
}
.bgsidebar {
  background-color: #66767d;
}
.bgtable {
  background-color: #d2e8f1;
}

.bgorange {
  background-color: #fea119;
}
.bglightorange {
  background-color: #fbf4e0;
}

.bglightgray {
  background-color: #f7faf5;
}

.bglightpink{
  background-color:#faf3f5;
}

.bglightblue{
  background-color:#e0ebf4
}

.ul {
  text-decoration: underline;
}

.wrap{
  word-break: break-all
}

.prewrap{
  white-space: pre-wrap;
}


.backimg{
  background-image: url('/images/Login-Background.jpg');
  height: 99vh;
}
.hfull{
  height:100vh
}

@media (max-width: 720px) {
  .container-p {
    padding: 10px 10px 10px 10px !important;
  }
  .fs30{
    font-size: 25px;
  }
}

.base {
  font-size: 14px;
  width: 440px;
  position: relative;
  background-color: #fff;
  margin: 5% auto;
  padding: 10px;
  text-align: center;
  font-family: inherit;
  border-style: solid;
  border-color: #77c146;
}
.checkboxtext {
  margin-left: 25px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding-left: 3px;
}
.checkbox {
  margin-right: 5px;
  zoom: 1;
}
.corporatename {
  color: red;
}
.trustedroadsname {
  color: #414141;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.tilte {
  color: #1a1919;
  font-weight: 500;
  border-radius: 10px;
  background: #ffeb3b;
  padding: 0px 5px 0px 5px;
}
.data {
  margin-left: 10px;
  margin-bottom: 5px;
}
.programs {
  background: #f4f1f1;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 0px 5px 0px;
}
.button {
  background-color: #ff661b;
  color: #fff;
  margin-left: 10px;
  padding: 10px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-weight: 600;
}
.popuppanel {
  display: none;
  position: fixed;
  z-index: 100000;
  left: 0px;
  top: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.closebutton {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0);
  fill: #ff661b;
  padding: 0px;
  margin: 0px;
  display: block;
  width: 25px;
  height: 25px;
  float: none;
}
@media only screen and (max-width: 600px) {
  .base {
    font-size: 18px;
    width: 90%;
  }
  .programs {
    padding: 10px 0px 10px 0px;
  }
  .data {
    margin-bottom: 10px;
  }
  .closebutton {
    width: 40px;
    height: 40px;
  }
  .checkboxtext {
    font-size: 18px;
    padding-left: 10px;
  }
  .checkbox {
    zoom: 1.75;
  }
}

