.base {
  font-size: 14px;
  width: 440px;
  position: relative;
  background-color: #fff;
  margin: 5% auto;
  padding: 10px;
  text-align: center;
  font-family: inherit;
  border-style: solid;
  border-color: #77c146;
}
.checkboxtext {
  margin-left: 25px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding-left: 3px;
}
.checkbox {
  margin-right: 5px;
  zoom: 1;
}
.corporatename {
  color: red;
}
.trustedroadsname {
  color: #414141;
  text-decoration-line: underline;
}
.tilte {
  color: #1a1919;
  font-weight: 500;
  border-radius: 10px;
  background: #ffeb3b;
  padding: 0px 5px 0px 5px;
}
.data {
  margin-left: 10px;
  margin-bottom: 5px;
}
.programs {
  background: #f4f1f1;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 0px 5px 0px;
}
.button {
  background-color: #ff661b;
  color: #fff;
  margin-left: 10px;
  padding: 10px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-weight: 600;
}
.popuppanel {
  display: none;
  position: fixed;
  z-index: 100000;
  left: 0px;
  top: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.closebutton {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0);
  fill: #ff661b;
  padding: 0px;
  margin: 0px;
  display: block;
  width: 25px;
  height: 25px;
  float: none;
}
@media only screen and (max-width: 600px) {
  .base {
    font-size: 18px;
    width: 90%;
  }
  .programs {
    padding: 10px 0px 10px 0px;
  }
  .data {
    margin-bottom: 10px;
  }
  .closebutton {
    width: 40px;
    height: 40px;
  }
  .checkboxtext {
    font-size: 18px;
    padding-left: 10px;
  }
  .checkbox {
    zoom: 1.75;
  }
}
